import { GetStaticProps } from "next";
import { IHTMLSection } from "@/components/Content/interfaces";
import Section from "@/components/Content/Section";
import { getStaticPageData } from "@/helpers/api/service/Staticpage";
import { TypeSetInnerHTML } from "@/helpers/typeset";
import MetaSeo from "@/components/Head/Meta";
import SitelinkSearchBoxSEO from "@/components/Head/SitelinkSearchBox";
import { StaticPageProps } from "@/components/StaticPage/interface";

import dynamic from "next/dynamic";

const RedirectHome = dynamic(() => import("@/components/Content/RedirectHome"));

const HomePage = (props: StaticPageProps) => {
  return (
    <RedirectHome>
      <div className={`static-content body-content ${props?.data.data.props?.className ?? ""}`}>
        <MetaSeo />
        <SitelinkSearchBoxSEO />
        <Section {...props.data.data} />
      </div>
    </RedirectHome>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const filter = {
    title: "home",
    source: "category"
  };

  const { meta, data } = await getStaticPageData("en", filter);

  if (!data || !(data as { data: IHTMLSection }).data || meta?.code !== 200) {
    if (meta?.code === 307 && data) {
      return {
        notFound: true,
      };
    }

    return {
      notFound: true,
    };
  }

  await TypeSetInnerHTML((data as { data: IHTMLSection }).data);

  return {
    props: {
      source: filter.source,
      data,
      breadcrumbs: {
        items: (data as { breadcrumbs: any }).breadcrumbs || null,
      },
      seo: (data as { seo: any }).seo || null,
      keywords: [],
    },
    // 15 min
    revalidate: 900,
  };
};

export default HomePage;

