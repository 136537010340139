import { useRouter } from "next/router";

const schemaData = {
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "WebSite",
      "@id": "https://www.doubtnut.com/#website",
      "url": "https://www.doubtnut.com/",
      "name": "Doubtnut",
      "description": "NCERT Solutions for Class 6, 7, 8, 9, 10, 11 and 12",
      "publisher": {
        "@id": "https://www.doubtnut.com/#organization"
      },
      "potentialAction": [
        {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.doubtnut.com/search?q={search_term_string}"
          },
          "query-input": "required name=search_term_string"
        }
      ],
      "inLanguage": "en"
    },
  ]
};

const SitelinkSearchBoxSEO = () => {
  return <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
  >
  </script>
}

export default SitelinkSearchBoxSEO;
